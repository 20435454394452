<template>
  <div>
    <div v-if="userType === 'admin'" class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <MailTemplatesTable></MailTemplatesTable>
      </div>
    </div>
    <div v-if="userType !== 'admin'" class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <Denied></Denied>
      </div>
    </div>
  </div>
</template>

<script>
import MailTemplatesTable from "@/components/Admins/Settings/Email/MailTemplates/Table";
import Denied from "@/components/SYS/errors/Denied";

export default {
  name: "AdminsSettingsEmailMailTemplatesIndex",
  components: {
    MailTemplatesTable,
    Denied
  },
  data() {
    return {
      userType: null
    };
  },
  mounted() {
    this.userType = this.$store.getters.userType;
  }
};
</script>
