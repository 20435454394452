import ApiService from "@/core/services/api.service";
import store from "@/core/services/store";

let url = process.env.VUE_APP_API_ADMIN;
let urlTenant = process.env.VUE_APP_API_TENANT;

class mailTemplates {
  get(id) {
    return ApiService.get(url, "mail_templates/" + id);
  }
  getAll(params, cancelToken) {
    let options = new URLSearchParams(params).toString();
    let headers = {};
    if (cancelToken) {
      headers.cancelToken = cancelToken;
    }
    if (store.getters.userType === "admin") {
      return ApiService.get(url, "mail_templates?" + options, headers);
    }
    return ApiService.get(urlTenant, "mail_templates?" + options, headers);
  }
  store(data) {
    return ApiService.post(url + "/mail_templates", data);
  }
  update(roleId, data) {
    return ApiService.put(url + "/mail_templates/" + roleId, data);
  }
  delete(id) {
    return ApiService.delete(url + "/mail_templates/" + id);
  }
}
export default new mailTemplates();
