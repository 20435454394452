<template>
  <div id="mailTemplatesCard" class="card card-custom grid-stack-item-content">
    <div class="card-header" style="cursor: move">
      <div class="card-title">
        <h3 class="card-label">{{ $t("mailTemplates.mailTemplates") }}</h3>
      </div>
      <div class="card-toolbar">
        <a
          href="#"
          class="btn btn-icon btn-sm btn-hover-light-primary ml-1"
          data-card-tool="reload"
          data-toggle="tooltip"
          data-placement="top"
          :title="$t('general.reload')"
          @click.prevent="refreshCard($event)"
        >
          <i class="fal fa-arrows-rotate"></i>
        </a>
        <a
          href="#"
          class="btn btn-icon btn-sm btn-hover-light-primary ml-1"
          data-card-tool="remove"
          data-toggle="tooltip"
          data-placement="top"
          :title="$t('general.remove')"
          @click.prevent="closeCard($event)"
        >
          <i class="fal fa-xmark"></i>
        </a>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <div class="card-body column">
      <TableWrapper :meta="meta" @reload-data="loadMailTemplates">
        <b-table
          responsive
          :items="mailTemplates"
          :fields="fields"
          class="dataTable table table-head-custom"
          :show-empty="true"
          :empty-text="$t('table.noRecords')"
        >
          <template #cell(actions)="data">
            <b
              class="btn btn-icon btn-light btn-sm mr-1"
              @click="showDetails(data.item.id)"
            >
              <i class="fal fa-pen"></i>
            </b>
          </template>
          <template #cell(name)="data">
            <div class="row flex-wrap py-3">
              <div class="col-lg-3 symbol symbol-50 py-0">
                <div
                  class="symbol-label symbol-label-details"
                  :style="
                    data.item.logo
                      ? 'background-image: url(' +
                        data.item.logo +
                        '); background-color: #ffffff;'
                      : ''
                  "
                ></div>
              </div>
              <div class="col-lg-9 py-0">
                <span class="d-block">{{ data.item.name }}</span>
                <span class="text-muted">{{ data.item.number }}</span>
              </div>
            </div>
          </template>
          <template #cell(active)="data">
            <span
              v-if="data.item.active"
              class="label label-lg label-light-success label-inline"
            >
              {{ $t("general.active") }}
            </span>
            <span v-else class="label label-lg label-light-danger label-inline">
              {{ $t("general.inactive") }}
            </span>
          </template>
          <template #cell(initialized)="data">
            <div v-if="data.item.initialized">
              <span class="text-success">{{ $t("general.yes") }}</span>
            </div>
            <div v-else>
              <span class="text-danger">{{ $t("general.no") }}</span>
            </div>
          </template>
        </b-table>
      </TableWrapper>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { formatDate } from "@/components/Tools/modifiers";

import MailTemplates from "@/components/Admins/Settings/Email/MailTemplates/mailTemplates";
import TableWrapper from "@/components/Tools/TableWrapper";
import { mapGetters } from "vuex";

export default {
  name: "AdminsSettingsEmailMailTemplatesTable",
  components: { TableWrapper },
  data() {
    return {
      mailTemplates: [],
      fields: [
        {
          key: "id",
          label: this.$t("table.id"),
          sortable: true
        },
        {
          key: "mailable_classname",
          label: this.$t("mailTemplates.className"),
          sortable: true
        },
        {
          key: "label",
          label: this.$t("mailTemplates.name"),
          sortable: true
        },
        {
          key: "updated_at",
          formatter: "formatDateAssigned",
          label: this.$t("table.updatedAt"),
          sortable: true,
          thStyle: { width: "180px" }
        },
        {
          key: "created_at",
          formatter: "formatDateAssigned",
          label: this.$t("table.createdAt"),
          sortable: true,
          thStyle: { width: "180px" }
        },
        {
          key: "actions",
          label: this.$t("table.actions"),
          sortable: false,
          thStyle: { width: "170px" }
        }
      ],
      meta: {},
      isBusy: false
    };
  },
  computed: {
    ...mapGetters("route", ["requestParams", "cancelToken"])
  },
  mounted() {
    this.loadMailTemplates();
    this.$root.$on("mailTemplatesTableRefresh", () => {
      this.refreshCard();
    });
  },
  methods: {
    loadMailTemplates() {
      this.isBusy = true;
      let params = this.requestParams();
      let cancelToken = this.cancelToken();
      MailTemplates.getAll(params, cancelToken)
        .then(response => {
          this.mailTemplates = response.data.data;
          this.mailTemplatesMeta = response.data.meta;
          this.isBusy = false;
        })
        .catch(error => {
          this.$error(error);
          this.isBusy = this.axios.isCancel(error);
        });
    },
    formatDateAssigned(value) {
      return formatDate(value);
    },

    closeCard() {
      $("#mailTemplatesCard").fadeOut(300, function () {
        $("#mailTemplatesCard").parent().remove();
      });
      this.$destroy();
    },
    refreshCard() {
      this.loadMailTemplates();
    },
    showDetails(id) {
      this.$router.push({
        name: "adminSettingsMailTemplatesDetails",
        params: { id: id }
      });
    }
  }
};
</script>

<style lang="scss">
.table td {
  vertical-align: middle;
}
</style>
